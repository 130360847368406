import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';
import { format } from 'date-fns';

import { getCampaignDetails } from 'actions/campaigns';
import { FNS_BACKEND_DATE_FORMAT } from 'config/app';

// import toast from 'utils/toast';
// import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import * as api from './campaignPayment.api';
import type {
  PaymentContact,
  FetchPaymentTaskParams,
  FetchPaymentTaskResponse,
  CreatePaymentTaskParams,
  UpdatePaymentTaskParams,
  BatchUpdateStatusParams,
  CreatePaymentTaskResponse,
  VerifyPaymentTaskParams,
  VerifyPaymentTaskResponse,
  UpdatePaymentTaskResponse,
} from './campaignPayment.types';

// Payment Contact
export const fetchPaymentContact = createAsyncThunk<
  PaymentContact,
  void,
  { state: RootState }
>('payment/fetchPaymentContact', async () => {
  const response = await api.fetchPaymentContact();
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const updatePaymentContact = createAsyncThunk<
  PaymentContact,
  PaymentContact,
  { state: RootState }
>('payment/updatePaymentContact', async (paymentContact) => {
  const response = await api.updatePaymentContact(paymentContact);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const validatePhoneNumber = createAsyncThunk<
  void,
  { phoneNumber: string },
  { state: RootState }
>('payment/validatePhoneNumber', async ({ phoneNumber }) => {
  const response = await api.validatePhoneNumber(phoneNumber);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const verifyValidationCode = createAsyncThunk<
  VerifyPaymentTaskResponse,
  { verificationCode: string },
  { state: RootState }
>('payment/verificationCode', async ({ verificationCode }) => {
  const response = await api.verifyValidationCode(verificationCode);
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

// Payment Task
export const fetchPaymentTask = createAsyncThunk<
  FetchPaymentTaskResponse,
  FetchPaymentTaskParams | undefined,
  { state: RootState }
>('payment/fetchPaymentTask', async (params, { getState }) => {
  let _params: FetchPaymentTaskParams;
  if (!params) {
    // `sortColumn` and `sortDirection` are not transformed to `sort` param here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasMore, sortColumn, sortDirection, total, ...filters } =
      getState().campaignPayment.paginationAndFilters;
    const campaign_id = getState().views.campaignDetails.id;
    if (!campaign_id) return Promise.reject(new Error('No campaign ID'));
    _params = {
      campaign_id,
      scopes: 'profiles,users,global_budget',
      ...filters,
    };
  } else {
    _params = {
      ...params,
      scopes: params.scopes || 'profiles,users,global_budget',
    };
  }

  const response = await api.fetchPaymentTask(_params);
  if (response && !response.error) {
    return { response, params: _params };
  }
  return Promise.reject(response.error);
});

export const createPaymentTask = createAsyncThunk<
  CreatePaymentTaskResponse,
  CreatePaymentTaskParams,
  { state: RootState }
>('payment/createPaymentTask', async (params, { dispatch }) => {
  const response = await api.createPaymentTask({
    amount: Number(params.amount),
    campaign_id: Number(params.campaign_id),
    currency: params.currency,
    due_date: format(new Date(params.due_date), FNS_BACKEND_DATE_FORMAT),
    public_profile_ids: params.public_profile_ids,
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    return response as CreatePaymentTaskResponse;
  }
  return Promise.reject(response.error);
});

export const updatePaymentTask = createAsyncThunk<
  UpdatePaymentTaskResponse,
  UpdatePaymentTaskParams,
  { state: RootState }
>('payment/updatePaymentTask', async (params, { dispatch }) => {
  const dateToUpdate = Object.assign(
    {},
    {
      id: params.id,
      campaign_id: Number(params.campaign_id),
      scopes: 'profiles,users',
    },
    params.amount && { amount: Number(params.amount) },
    params.currency && { currency: params.currency },
    params.due_date && {
      due_date: format(new Date(params.due_date), FNS_BACKEND_DATE_FORMAT),
    },
  );

  const response = await api.updatePaymentTask(dateToUpdate);
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    return response;
  }
  return Promise.reject(response.error);
});

export const verifyPaymentTask = createAsyncThunk<
  VerifyPaymentTaskResponse,
  VerifyPaymentTaskParams,
  { state: RootState }
>('payment/verifyPaymentTask', async (params) => {
  const response = await api.createPaymentTask({
    campaign_id: Number(params.campaign_id),
    public_profile_ids: params.public_profile_ids,
    allowed_currencies: true,
  });

  if (response && !response.error) {
    return response as VerifyPaymentTaskResponse;
  }
  return Promise.reject(response.error);
});

export const batchUpdateStatusPaymentTask = createAsyncThunk<
  { response: any; params: BatchUpdateStatusParams },
  BatchUpdateStatusParams,
  { state: RootState }
>('payment/batchUpdateStatusPaymentTask', async (params, { dispatch }) => {
  const response = await api.batchUpdateStatus({
    ids: params.ids,
    campaign_id: Number(params.campaign_id),
    status: params.status,
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaign_id));
    void dispatch(fetchPaymentTask());
    return { response, params };
  }
  return Promise.reject(response.error);
});

export const fetchSinglePaymentTask = createAsyncThunk<
  UpdatePaymentTaskResponse,
  { id: number; campaignId: number },
  { state: RootState }
>('payment/fetchSinglePaymentTask', async (params) => {
  const response = await api.fetchSinglePaymentTask({
    id: Number(params.id),
    campaign_id: Number(params.campaignId),
    scopes: 'profiles,users',
  });
  if (response && !response.error) {
    return response;
  }
  return Promise.reject(response.error);
});

export const removePaymentTask = createAsyncThunk<
  { params: { id: number; campaignId: number } },
  { id: number; campaignId: number },
  { state: RootState }
>('payment/removePaymentTask', async (params, { dispatch }) => {
  const response = await api.removePaymentTask({
    id: Number(params.id),
    campaign_id: Number(params.campaignId),
  });
  if (response && !response.error) {
    void dispatch(getCampaignDetails(params.campaignId));
    return { params };
  }
  return Promise.reject(response.error);
});
