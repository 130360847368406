import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useOktaAuth, OktaContext } from '@okta/okta-react';

import { Loader } from 'kolkit';
import { oktaLogin } from 'api/auth';

let handledRedirect = false;

const OktaLoginCallback = ({ errorComponent, loadingElement = null, onAuthResume }) => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = useState(null);

  const ErrorReporter = errorComponent || OktaContext.OktaError;
  useEffect(() => {
    const isInteractionRequired = oktaAuth.idx.isInteractionRequired || oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    if (!handledRedirect) {
      oktaAuth.handleLoginRedirect().catch(e => {
        setCallbackError(e);
      })
      handledRedirect = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]);

  useEffect(() => {
    (async () => {
      if (authState?.isAuthenticated) {
        await oktaLogin({
          id_token: authState?.idToken?.idToken,
          access_token: authState?.accessToken?.accessToken
        });
        navigate('/');
      }
    })();
  }, [authState, navigate]);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError}/>;
  }

  return loadingElement || <Loader />;
};

export default OktaLoginCallback;