import React, { memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const isNotNull = (v) => v && v.trim() !== '' && v.trim() !== '​\n';

/**
 * INFO: for <a /> links: if you want to open links in new tab, surround the text with "=="
 * ex: [==read the faq==](https://www.fag.link/article)
 *
 */

const Markdown = ({ children, className = '' }) => {
  const content = useMemo(
    () => (Array.isArray(children) || isNotNull(children) ? children : ''),
    [children],
  );

  const parseContent = (toParse) => {
    let markdownParsed = toParse;
    const matchesUnderline = toParse.match(/\++.+?\++/g);
    if (matchesUnderline?.length) {
      matchesUnderline.forEach((mark) => {
        markdownParsed = markdownParsed.replaceAll(
          mark,
          `~~${mark.replaceAll('++', '').trim()}~~`,
        );
      });
    }
    return markdownParsed;
  };

  const markdownParsed = Array.isArray(content)
    ? content.map(parseContent).join('\n')
    : parseContent(content);

  return (
    <span className={className}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          del: (props) => <ins>{props.children}</ins>,
          a: (props) => {
            const text = props.children?.[0];
            return text &&
              typeof text === 'string' &&
              text.startsWith('==') &&
              text.endsWith('==') ? (
              <a href={props.href} target="_blank" rel="noreferrer">
                {React.Children.toArray(
                  props.children.map((child) => child.replaceAll('==', '')),
                )}
              </a>
            ) : (
              <a href={props.href}>{props.children}</a>
            );
          },
        }}
      >
        {markdownParsed}
      </ReactMarkdown>
    </span>
  );
};

export default memo(Markdown);
