import React, { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import { useSelector } from 'utils/redux';
import { Modal2, Text } from 'kolkit';
import styles from 'css/scss/ui/api-error-modale.module.scss';

const ApiErrorModale = memo(({ isRuntimeError }) => {
	const intl = useIntl();
	const code = useSelector(
		({ ui }) => ui?.modales?.apiError?.code || null
	);
	const reloadPage = useCallback(
		() => {
			window.location.reload(true)
		},
		[]
	);

	const action = useCallback(
		() => ({
			primary: {
				title: intl.formatMessage({ id: "global.cta.reloadApp" }),
				onClick: reloadPage,
			}
		}),
		[intl, reloadPage]
	);


	return (
		<Modal2
			open
			title={code !== null
				? intl.formatMessage({ id: code ? "errorModale.titleKnown" : "errorModale.titleUnknown"}, { code })
				: ''
			}
			action={action}
			isAlert
		>
			<Text className={styles.content}>
				<FormattedMessage
					id={(code === 'runtime' || isRuntimeError) ? "errorModale.runtime" : "errorModale.critical"}
				/>
			</Text>
		</Modal2>
	);
});

export default ApiErrorModale;
