/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  createPaymentTask,
  updatePaymentTask,
  removePaymentTask,
  fetchPaymentTask,
  fetchPaymentContact,
  fetchSinglePaymentTask,
} from './campaignPayment.actions';
import { SliceState } from './campaignPayment.types';

import { startLoading, stopLoading } from '../utils';

const initialState: SliceState = {
  loading: [],
  paymentContact: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  allPayouts: {},
  currentDisplayedPayoutIds: [],
  kolOverviewPayouts: [],
  globalBudget: {
    available: '',
    due: '',
    next: '',
    paid: 0,
    project_currency: 'USD',
    total: '',
  },
  paginationAndFilters: {
    page: 0,
    per_page: 10,
    total: 0,
    search: '',
    sort: '',
    hasMore: true,
    sortColumn: '',
    sortDirection: 'desc',
  },
};

// Reducers
const campaignPaymentSlice = createSlice({
  name: 'campaignPayment',
  initialState,
  reducers: {
    updateKey: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    updatePaginationAndFilters: (state, action) => {
      let { sort } = action.payload;
      if (!sort && action.payload.sortColumn && action.payload.sortDirection) {
        sort = `${action.payload.sortColumn}-${action.payload.sortDirection}`;
      }
      state.paginationAndFilters = {
        ...state.paginationAndFilters,
        ...action.payload,
        sort,
      };
    },
    resetPaginationAndFilters: (state) => {
      state.paginationAndFilters = {
        ...initialState.paginationAndFilters,
        tab: state.paginationAndFilters.tab,
      };
    },
  },
  extraReducers: (builder) => {
    // getCampaignWorkflows
    builder
      .addCase(fetchPaymentContact.pending, (state) => {
        startLoading(state, fetchPaymentContact);
      })
      .addCase(fetchPaymentContact.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentContact = action.payload;
        }
        stopLoading(state, fetchPaymentContact);
      })
      .addCase(fetchPaymentContact.rejected, (state) => {
        stopLoading(state, fetchPaymentContact);
      });
    // fetchPaymentTask
    builder
      .addCase(fetchPaymentTask.pending, (state) => {
        startLoading(state, fetchPaymentTask);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(fetchPaymentTask.fulfilled, (state, action) => {
        // Update global budget
        if (action.payload.response.global_budget) {
          state.globalBudget = action.payload.response.global_budget;
        }

        // Update table view
        const { tab, page } = action.payload.params;

        if (!tab) return;

        state.paginationAndFilters = {
          ...state.paginationAndFilters,
          total: action.payload.response.total_count,
          hasMore:
            action.payload.response.page + 1 <
            Math.ceil(
              action.payload.response.total_count /
                action.payload.response.per_page,
            ),
          page: action.payload.response.page,
        };

        if (tab === 'overview') {
          state.kolOverviewPayouts =
            page === 0
              ? action.payload.response.payouts
              : [
                  ...state.kolOverviewPayouts,
                  ...action.payload.response.payouts,
                ];
        } else {
          // Store all payouts in state regardless of pagination and filters
          state.allPayouts = {
            ...state.allPayouts,
            ...action.payload.response.payouts.reduce(
              (payouts, payout) =>
                payout.id ? { ...payouts, [payout.id]: payout } : payouts,
              {},
            ),
          };

          const newPayoutIds = action.payload.response.payouts
            .map((payout) => payout.id)
            .filter((id) => !!id);
          state.currentDisplayedPayoutIds =
            page === 0
              ? newPayoutIds
              : [...state.currentDisplayedPayoutIds, ...newPayoutIds];
        }
        stopLoading(state, fetchPaymentTask);
      })
      .addCase(fetchPaymentTask.rejected, (state) => {
        stopLoading(state, fetchPaymentTask);
      });

    // createPaymentTask
    builder
      .addCase(createPaymentTask.pending, (state) => {
        startLoading(state, createPaymentTask);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .addCase(createPaymentTask.fulfilled, (state, action) => {
        // if (action.payload) {
        //   state.createPaymentTask = action.payload;
        // }
        stopLoading(state, createPaymentTask);
      })
      .addCase(createPaymentTask.rejected, (state) => {
        stopLoading(state, createPaymentTask);
      });

    // updatePaymentTask
    builder
      .addCase(updatePaymentTask.pending, (state) => {
        startLoading(state, updatePaymentTask);
      })
      .addCase(updatePaymentTask.fulfilled, (state, action) => {
        if (action.payload.id) {
          const payoutToUpdate = state.allPayouts[action.payload.id];
          if (!payoutToUpdate) return;
          payoutToUpdate.amount = action.payload.amount;
          payoutToUpdate.due_date = action.payload.due_date;
          // payoutToUpdate.currency = action.payload.currency;

          state.allPayouts[action.payload.id] = payoutToUpdate;
        }
        stopLoading(state, updatePaymentTask);
      })
      .addCase(updatePaymentTask.rejected, (state) => {
        stopLoading(state, updatePaymentTask);
      });

    // fetchSinglePaymentTask
    builder
      .addCase(fetchSinglePaymentTask.pending, (state) => {
        startLoading(state, fetchSinglePaymentTask);
      })
      .addCase(fetchSinglePaymentTask.fulfilled, (state, action) => {
        const { id } = action.payload;
        if (state.allPayouts[id]) {
          state.allPayouts[id] = {
            ...state.allPayouts[id],
            ...action.payload,
          };
        }
        stopLoading(state, fetchSinglePaymentTask);
      })
      .addCase(fetchSinglePaymentTask.rejected, (state) => {
        stopLoading(state, fetchSinglePaymentTask);
      });

    // removePaymentTask
    builder
      .addCase(removePaymentTask.pending, (state) => {
        startLoading(state, removePaymentTask);
      })
      .addCase(removePaymentTask.fulfilled, (state, action) => {
        if (action.payload.params.id) {
          delete state.allPayouts[action.payload.params.id];
          state.currentDisplayedPayoutIds =
            state.currentDisplayedPayoutIds.filter(
              (id) => id !== action.payload.params.id,
            );
        }
        stopLoading(state, removePaymentTask);
      })
      .addCase(removePaymentTask.rejected, (state) => {
        stopLoading(state, removePaymentTask);
      });
  },
});

// action creators
export const { actions } = campaignPaymentSlice;

export default campaignPaymentSlice.reducer;
