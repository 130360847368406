import createTheme from '@mui/material/styles/createTheme';
import zIndex from 'config/zIndex';

export default createTheme({
  palette: {
    primary: {
        main: '#0c2329',
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          color: 'var(--color-dominant-on-dominant)',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'var(--color-dominant-on-dominant)',
          fontFamily: 'var(--font-family)',
          transition: "background-color .3s ease",
          height: "var(--size-sizing-medium)",
          '&:hover': {
            backgroundColor: 'var(--color-primary-300)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#0c2329',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: `${zIndex.modale} !important`,
        }
      },
      defaultProps: {
        BackdropProps: {
          sx: {
            backgroundColor: 'rgba(0,48,87,0.5)',
          }
        }
      }
    },
    MuiDrawer: {
      defaultProps: {
        BackdropProps: {
          sx: {
            backgroundColor: 'rgba(0,48,87,0.5)',
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: '#003057',
          fontSize: 12,
          borderWidth: 0,
          padding: 12,
        },
        notchedOutline: {
          borderColor: '#CCD6E3',
        },
      },
    },
    // Allow focusing on zendesk chat even if a modal is opened
    // https://github.com/mui-org/material-ui/issues/17497
    MuiModal: {
      defaultProps: {
        disableEnforceFocus: true,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: 'var(--color-primary-300)',
          flex: '0 0 4px',
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 3,
          marginBottom: -4,
        },
        bar: {
          background: 'linear-gradient(103deg, var(--color-green-300) -13.82%, var(--color-blue-400) 50.29%, var(--color-green-300) 121.29%)',
        }
      }
    },
  },
  zIndex: {
    // Fix Tooltip displayed behind select inside Portal
    drawer: 0,
  }
});
