/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper'

import autoUpdateKeys from 'utils/autoUpdateKeys';
import * as CAMPAIGNS from 'constants/campaigns'
import * as PROFILES from 'constants/profiles';
import { filters } from 'config/init';
import { Campaign, CampaignReducerState } from 'types/campaigns';

const detailedStructure: Campaign = {
  id: null,
  enlisted: false,
  core: {},
  profiles: {},
  reporting: {},
  admin: {},
  objectives: {},
  brief: {},
  product: {},
  usersRoles: [],
  targeting: {
    filters: {
      ...filters
    }
  },
  affilae: {},
  posts: {
    total: 0,
    page: 0,
    perPage: 48,
    hasMore: true,
    rows: [],
  },
  discount_codes: [],
  tracking_links: {
    search: '',
    sort: { key: '', dir: 'asc' },
    paginate: { page: 0, per_page: 15 },
    rows: [],
  },
  tracking_links_reporting: {}
} as unknown as Campaign;

export const profileStructure = {
  id: null,
  state: null,
  addedAt: null,
  unreadMessages: 0,
  lastMessageSentAt: null,
  conversationId: null,
  delivery: {},
  lang: null
};

const initialState:CampaignReducerState = {};

const initDate = new Date().toISOString();

const reducers = {
  [CAMPAIGNS.ADD]: (state, {payload:{id, ...datas}}) => update(state, {
    $merge: {
      [id]: {
        ...detailedStructure,
        ...datas,
        id,
      }
    }
  }),

  [CAMPAIGNS.UPDATE]: (state, { payload: { id, ...datas }}) => {
    // Campaign do not exists. You should have done an ADD, but anyway, I will handle it for you!
    if (!state[id]) return update(state, {
      $merge: {
        [id]: {
          ...detailedStructure,
          ...datas,
          id,
        }
      }
    });

    // Update campaign
    return update(state, {
      [id]: {
        $apply: autoUpdateKeys(datas)
      }
    });
  },

  [CAMPAIGNS.REMOVE]: (state, {payload:id}) => update(state, {
    $unset: [id]
  }),

  [CAMPAIGNS.BATCH]: (state, { payload: campaigns }) => campaigns.reduce((c, { id, ...datas }) => {
    // campaign doesn't exists
    if (!c[id]) return update(c, {
      $merge: {
        [id]: {
          ...detailedStructure,
          ...datas,
          id,
        }
      }
    });

    // Update all campaign keys
    return update(c, {
      [id]: {
        $apply: autoUpdateKeys(datas)
      }
    })
  }, state),
  [CAMPAIGNS.BATCH_SET]: (state, { payload: campaigns }) => {
    const allCampaigns = campaigns.reduce((c, { id, ...datas }) => ({
      ...c,
      [id]: {
        ...detailedStructure,
        ...datas,
        id,
      }
    }), {});

    return update(state, {
      $set: allCampaigns
    })
  },

  [CAMPAIGNS.ADD_PROFILES]: (state, {payload: { campaignId, profileIds, profilesCount, ...data }}) => [profileIds].flat().reduce((p, id) => {
    if (!id || !campaignId) return null;

    if (p[campaignId].profiles[id]) return p;

    return update(p, {
      [campaignId]: {
        profiles: {
          $merge: {
            [id]: {
              ...profileStructure,
              ...data,
              addedAt: initDate,
              id
            }
          }
        },
        core: {
          profilesCount: {
            $set: profilesCount
          },
          updatedAt: {
            $set: initDate
          }
        }
      }
    })
  }, state),

  [CAMPAIGNS.UPDATE_PROFILE]: (state, {payload:{id, profileId, ...datas}}) => update(state, {
    [id]: {
      profiles: {
        [profileId]: {
          $apply: autoUpdateKeys(datas)
        }
      }
    }
  }),

  [CAMPAIGNS.UPDATE_PROFILES]: (state, {payload:{id, profiles}}) => profiles.reduce((p, {id: profileId, ...datas}) => {
    // SNA doesn't exists yet
    if (!p[id].profiles[profileId]) return update(p, {
      [id]: {
        profiles: {
          $merge: {
            [profileId]: {
              ...profileStructure,
              ...datas,
              id: profileId,
            }
          }
        }
      }
    });

    // Update all SNA keys with merge if object, or set
    return update(p, {
      [id]: {
        profiles: {
          [profileId]: {
            $apply: autoUpdateKeys(datas)
          }
        }
      }
    })
  }, state),

  [CAMPAIGNS.REMOVE_PROFILES]: (state, { payload:{ campaignId, profileIds, profilesCount }}) => profileIds.reduce((p, id) =>
    update(p, {
      [campaignId]: {
        profiles: {
          $unset: [id]
        },
        core: {
          profilesCount: {
            $set: profilesCount
          },
          updatedAt: {
            $set: initDate
          }
        }
      }
    })
  , state),

  [CAMPAIGNS.PUSH_PROFILES]: (state, { payload: { campaignId, profiles, page } }) => {
    if (page === 0) state[campaignId].profiles = profiles;
    if (page !== 0) state[campaignId].profiles = {
      ...state[campaignId].profiles,
      ...profiles
    };
  },

  [CAMPAIGNS.RESET_PROFILES]: (state, { payload: { campaignId } }) => {
    state[campaignId].profiles = {}
  },

  [CAMPAIGNS.GET_POSTS]: (state, { payload: { campaignId, total, posts, page } }) => {
    const getPosts = state[campaignId].posts;
    if (page === 0) getPosts.rows = posts;
    if (page !== 0) getPosts.rows.push(...Array.from(new Set(posts)));
    getPosts.total = total;
    getPosts.page = page + 1;
    getPosts.hasMore = page + 1 < Math.ceil(total / getPosts.perPage)
  },

  [CAMPAIGNS.RESET_POSTS]: (state, { payload: campaignId }) => {
    state[campaignId].posts = detailedStructure.posts;
  },

  [CAMPAIGNS.UPDATE_TRACKING_LINKS_PARAMS]: (state, { payload: { campaignId, ...params } }) => {
    if (!campaignId) return;
    if('searchValue' in params)
      state[campaignId].tracking_links.search = params.searchValue || '';
    if('sortKey' in params)
      state[campaignId].tracking_links.sort.key = params.sortKey || '';
    if('sortDir' in params)
      state[campaignId].tracking_links.sort.dir = params.sortDir || 'asc';
    if('page' in params)
      state[campaignId].tracking_links.paginate.page = params.page || 0;
  },

  [PROFILES.REMOVE_PROFILES_FROM_STORE]: (state, { payload: profileIds }) => {
    Object.keys(state).forEach((campaignId) => {
      const campaignProfileIds = Object.keys(state[campaignId].profiles);
      const idsToDelete = campaignProfileIds.filter((profileId) =>
        profileIds.includes(Number(profileId)),
      );
      if (idsToDelete?.length) {
        idsToDelete.forEach((id) => {
          delete state[campaignId].profiles[id];
        });
        state[campaignId].sortedProfiles.byName = state[
          campaignId
        ].sortedProfiles.byName.filter((id) => !idsToDelete.includes(id));
        state[campaignId].core.profilesCount -= idsToDelete.length;
      }
    });
  },

  [CAMPAIGNS.INCREASE_COMMENT_COUNT]: (
    state,
    { payload: { profileId, campaignId } },
  ) => {
    if (state[campaignId]?.profiles?.[profileId]) {
      state[campaignId].profiles[profileId].commentsCount += 1;
    }
  },
};

export default createReducer(initialState, reducers);
