export const POSTS_PER_PAGE = 12;
export const ADD_POST_PER_PAGE = 72;
export const MAX_POSTS = 10000;

export const MAX_PAGES = Math.ceil(MAX_POSTS / POSTS_PER_PAGE);

export const PUBLICATION_TYPES = {
  POST: 'post',
  STORY: 'story',
  REEL: 'reel',
  SAVED_STORY: 'saved_story',
  SPOTLIGHT: 'spotlight',
};

export const PUBLICATION_TYPES_LIST = [
  PUBLICATION_TYPES.POST,
  PUBLICATION_TYPES.STORY,
  PUBLICATION_TYPES.REEL
];

export const PUBLICATION_TYPES_LIST_PLURAL = {
  [PUBLICATION_TYPES.POST]: 'posts',
  [PUBLICATION_TYPES.STORY]: 'stories',
  [PUBLICATION_TYPES.REEL]: 'reels',
};

export const FILTERS_CONTENT_SORT = [
  'most_recent',
  'most_engaging',
  'most_engaging_rate',
  'most_liked',
  'most_commented',
];

export const REPORTING_SORTING_OPTIONS = [
  'most_recent',
  'most_engaging',
  'most_emv'
];

export const INTERACTIONS_BY_NETWORK = {
  instagramReels: ['likes', 'comments', 'impressions', 'plays', 'reach', 'saved', 'views'],
  instagramStories: ['impressions', 'reach', /* 'swipeUp', */ 'clicks', 'replies'],
  instagram: ['likes', 'comments', 'saved' ,'views', 'plays', 'impressions', 'reach'],
  youtube: ['likes', 'comments', 'views', 'shares', 'clicks'],
  tiktok: ['likes', 'comments', 'shares', 'saved', 'plays', 'impressions'],
  facebook: [
    'likes',
    'comments',
    'shares',
    'views',
    'impressions',
    'reach',
    'clicks',
  ],
  twitter: ['likes', 'shares', 'comments', 'views', 'impressions', 'clicks', 'reach'],
  snapchatSavedStories: ['views', 'reach', 'shares', 'swipeUp'],
  snapchatSpotlights: ['saved', 'views', 'reach', 'shares', 'screenshots'],
};

export const PUBLICATION_PRICE_TYPE = ['campaign', 'organic'];