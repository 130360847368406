import React, { useEffect, useMemo } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { unstable_HistoryRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import Loadable from 'react-loadable';

import Loader from 'kolkit/Loader';
import useTranslations, { projectIds } from 'utils/hooks/useTranslations';
import useCheckVersion from 'utils/hooks/useCheckVersion';
import { useSelector } from 'utils/redux';
import { conf } from 'config/env';

import { ActionCableProvider } from 'config/actioncable';
import IntlGlobalSingleton from 'utils/HOCs/IntlGlobalSingleton';

import history from 'config/history';
import routes from 'config/routes';
import theme from 'config/theme';

import MainContainer from 'components/MainContainer'
import AuthComponent from 'components/Auth'

import CookiesApprobation from 'components/modules/CookiesApprobation';
import CSPViolationListener from 'components/modules/CSPViolationListener';

import OktaLoginCallback from './Auth/components/Login/OktaLoginCallback';

const PublicPaneMonitor = Loadable({
  loader: () => import('components/campaigns/details/PublicPaneMonitor'),
  loading: Loader,
});

const PublicCastingValidation = Loadable({
  loader: () => import('components/campaigns/details/PublicCastingValidation/PublicCastingValidation'),
  loading: Loader,
});

const PublicContentValidation = Loadable({
  loader:() => import('components/campaigns/details/PublicContentValidation/PublicContentValidation'),
  loading: Loader
});

const RegisterHubspot = Loadable({
  loader: () => import('components/Auth/components/Register/RegisterHubspot'),
  loading: Loader,
});

LicenseInfo.setLicenseKey(conf.muiDatepicker);

const oktaAuth = new OktaAuth(conf.oktaConfig);

const App = () => {
  const { messages } = useTranslations();

  useCheckVersion();

  const { user, timeZone, locale, hasInternet } = useSelector(({ env, user }) => ({
    timeZone: env.timezone,
    locale: env.locale,
    user: user?.profile,
    hasInternet: env?.hasInternet,
  }));

  const localeIso2 = useMemo(
    () => {
      const initLocal = locale.slice(0, 2);
      return initLocal.startsWith('fr') ? initLocal : 'en';
    },
    [locale]
  );

  useEffect(
    () => {
      if (user?.canUpdatePhrase) initializePhraseAppEditor({
        projectId: projectIds.influence,
        accountId: conf.phraseAccountId,
        phraseEnabled: true,
        autoLowercase: false,
        fullReparse: true, // uses Virtual DOM. This allows proper reparsing when DOM changes are detected
      });
    },
  [user?.canUpdatePhrase]
  );

  const restoreOriginalUri = (_oktaAuth,  originalUri) => {
    history.push(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <IntlProvider
      messages={messages}
      locale={localeIso2}
      defaultLocale="en"
      timeZone={timeZone}
      onError={(error) => messages ? console.warn(error) : null}
    >
      <IntlGlobalSingleton />
      <CookiesApprobation />
      <CSPViolationListener />
      <GoogleOAuthProvider clientId={conf.gOAuthClientId}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ActionCableProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router history={history}>
                  <Security
                    oktaAuth={oktaAuth}
                    restoreOriginalUri={restoreOriginalUri}
                  >
                    <Routes>
                      <Route
                        path={`/${routes.login}`}
                        element={<AuthComponent />}
                      />
                      <Route
                        path={`/${routes.loginCallback}`}
                        element={<OktaLoginCallback />}
                      />
                      <Route
                        path={`/${routes.register}`}
                        element={<RegisterHubspot />}
                      />
                      <Route
                        path={`/${routes.publicReport}/:token`}
                        element={<PublicPaneMonitor />}
                      />
                      <Route
                        path={`/${routes.publicCasting}/:token`}
                        element={<PublicCastingValidation />}
                      />
                      <Route
                        path={`/${routes.publicContentValidation}/:token`}
                        element={<PublicContentValidation />}
                      />
                      <Route path="*" element={
                        <MainContainer
                          user={user}
                          locale={locale}
                          hasInternet={hasInternet}
                        />
                      } />
                    </Routes>
                  </Security>
                </Router>
              </LocalizationProvider>
            </ActionCableProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </IntlProvider>
  );
};

export default App;
