import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Modal2, Text } from 'kolkit';

import { useSelector } from 'utils/redux';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { minimumSupportedBrowserVersion, browserDownloadLinks } from 'config/browser';

import styles from 'css/scss/ui/unsupportedBrowserWarning.module.scss';

const IsUnsupportedBrowser = (browserName, browserVersion) => {
	const minBrowserVersion = minimumSupportedBrowserVersion[browserName];
	if (!minBrowserVersion) return true;
	return browserVersion < minBrowserVersion;
}

const OutdatedBrowserAlert = () => {
	const intl = useIntl()
	const [wishNoMoreAlert, setWishNoMoreAlert] = useState(false);
	const [isIgnored, setIsIgnored] = useLocalStorage('alert_outdated_browser_2024', false); // Change local storage key to force user to see the alert again
	const { browserName, browserVersion, isDesktop } = useSelector(({ env, ui }) => ({
		browserName: env.browser.name,
		browserVersion: env.browser.versionNumber,
		isDesktop: ui.device.isDesktop
	}));

	const unsupportedBrowser = IsUnsupportedBrowser(browserName, browserVersion);

	const [modalOpen, setModalOpen] = useState(unsupportedBrowser && !isIgnored);

	const closeModal = useCallback(
		() => {
			setModalOpen(open => !open);
			setIsIgnored(wishNoMoreAlert);
		},
		[setIsIgnored, wishNoMoreAlert]
	);

	const handleCheck = useCallback(
		() => setWishNoMoreAlert(s => !s),
		[],
	  );

	const openChromeDownloadPage = () => {
		closeModal();
		window.open(browserDownloadLinks.chrome, '_blank');
	};

	const actions = {
		primary: {
			title: intl.formatMessage({ id: "unsupportedBrowser.downloadChrome" }),
			onClick: openChromeDownloadPage
		},
		secondary: {
			title: intl.formatMessage({ id: "unsupportedBrowser.continueInDegradedMode" }),
			onClick: closeModal
		},
		checkbox: {
			title: intl.formatMessage({ id: "unsupportedBrowser.wishNoMoreAlert" }),
			checked: wishNoMoreAlert,
			onChange: handleCheck,
		},
	};

	if (!isDesktop || !unsupportedBrowser) return null;

	return (
		<Modal2
			on={modalOpen}
			action={actions}
			title={intl.formatMessage({ id: "unsupportedBrowser.title" })}
			onClick={closeModal}
			size="large"
		>
			<Text>
				<FormattedMessage id="unsupportedBrowser.description" />
				<a
					className={styles.updateBrowser}
					rel="noopener noreferrer"
					target="_blank"
					href={browserDownloadLinks[browserName] || ''}
				>
					<FormattedMessage id="unsupportedBrowser.update" />
				</a>
			</Text>
			<Text tag="span" className={styles.recommendation}>
				<FormattedMessage id="unsupportedBrowser.recommend" />
			</Text>
		</Modal2>
	)
};

export default OutdatedBrowserAlert;
